/*
 * @Date: 2022-01-20 11:11:27
 * @Description: desc
 */
import { createStore } from 'vuex'
import modules from './modules/index'

export default createStore({
  modules
})
