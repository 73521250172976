import { get, post, patch } from '@/utils/http'
import { SERVER_AGENT, SERVER_SERVER } from './api'

export const getAuthCode = (data) => {
  return get(`${SERVER_AGENT}/auth/image/check/code`, data, {}, 'blob')
}

export const login = (params) => {
  return post(`${SERVER_AGENT}/auth/login`, params)
}

export const getSafeCode = (params) => {
  return get(`${SERVER_SERVER}/system/code`, params)
}

// 修改代理商密码
export const updatePassword = (params) => {
  return patch(`${SERVER_AGENT}/payment/agent/modify/password`, params)
}
