<!--
 * @Date: 2022-02-14 11:58:37
 * @Description: desc
-->
<template>
  <a-config-provider
    :locale="locale"
  >
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { onMounted, computed, ref, watch } from '@vue/runtime-core'
import { mapState, useStore } from 'vuex'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
const store = useStore()
const langFn = mapState('setting', ['lang']).lang.bind({ $store: store })
const lang = computed(langFn)
const locale = ref({})
const setLanguage = (lang) => {
  switch (lang.value) {
    case 'CN':
      locale.value = require('ant-design-vue/es/locale-provider/zh_CN').default
      break
    case 'HK':
      locale.value = require('ant-design-vue/es/locale-provider/zh_TW').default
      break
    case 'US':
    default:
      locale.value = require('ant-design-vue/es/locale-provider/en_US').default
      break
  }
}
watch(locale, val => {
  dayjs.locale(val)
})
onMounted(() => {
  setLanguage(lang)
})
</script>

<style>
#app{
  height: 100%;
}
</style>
