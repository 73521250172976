import md5 from 'js-md5'
import { getSafeCode, login } from '@/apis/login'
import { decryptByAES } from '@/utils/security'
export default {
  state: {
    clientSessionId: '',
    userToken: '',
    safeCode: '',
    isLogin: false,
    paymentAgentSerialNumber: '',
    accessToken: ''
  },
  actions: {
    async getSafeCode({ commit, state }) {
      const params = {
        clientSessionId: state.clientSessionId,
        t: Date.now(),
        client: 'agent'
      }
      params.encrypt = md5(params.client + params.clientSessionId + params.t)
      const res = await getSafeCode(params)
      commit('setSafeCode', decryptByAES(res.data, '6f0c5ba452b24fa28989e9524d77407a'))
    },
    async login({ state, commit }, data) {
      const res = await login(data)
      if (res.code === 200) {
        commit('LOGIN')
        state.paymentAgentSerialNumber = res.data.paymentAgentSerialNumber
        localStorage.setItem('paymentAgentSerialNumber', res.data.paymentAgentSerialNumber)
        localStorage.setItem('paymentAgentName', res.data.paymentAgentName)
        localStorage.setItem('userToken', res.data.token)
        localStorage.setItem('accessToken', res.data.accessToken)
      } else {
        state.isLogin = false
        localStorage.removeItem('paymentAgentSerialNumber')
      }
    },
    setclientSessionId({ commit }) {
      let sessionId = new Date().getTime()
      const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (var i = 0; i < 10; i++) {
        sessionId += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      commit('SET_CLIENT_SESSION_ID', sessionId)
    }
  },
  mutations: {
    SET_CLIENT_SESSION_ID(state, res) {
      state.clientSessionId = res
      localStorage.setItem('clientSessionId', res)
    },
    setSafeCode(state, res) {
      state.safeCode = res
      localStorage.setItem('safeCode', res)
    },
    LOGIN(state) {
      state.isLogin = true
    },
    LOGOUT(state) {
      state.isLogin = false
      localStorage.removeItem('paymentAgentSerialNumber')
      localStorage.removeItem('paymentAgentName')
      localStorage.removeItem('clientSessionId')
      localStorage.removeItem('safeCode')
      localStorage.removeItem('userToken')
      localStorage.removeItem('accessToken')
    }
  }
}
