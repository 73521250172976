/*
 * @Date: 2022-02-14 14:06:19
 * @Description: desc
 */
import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/statistic',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/layouts/Layout.vue'),
    children: [
      {
        name: 'statistic',
        path: '/statistic',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/pages/payment/Statistic')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/pages/login/Login')
  },
  {
    name: 'payment',
    path: '/payment',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/pages/payment/UserPayment')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'payment') {
    if (!localStorage.getItem('paymentAgentSerialNumber')) {
      next({ name: 'login' })
      return
    }
  }
  next()
})

export default router
