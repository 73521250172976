/*
 * @Date: 2022-02-14 11:58:37
 * @Description: desc
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import './styles/index.less'
import Vant from 'vant'
import 'vant/lib/index.css'
import './rem.js'

const app = createApp(App)

app.use(Antd)
app.use(Vant)

app.use(router).use(store).mount('#app')
