import axios from 'axios'
// import moment from 'moment'
import { message } from 'ant-design-vue'
import md5 from 'js-md5'
// import { mapState } from 'vuex'
window.$md5 = md5
axios.interceptors.request.use(
  function(config) {
    const t = Date.now()
    config.headers.t = t
    config.headers.clientSession = localStorage.getItem('clientSessionId') || ''
    config.headers.client = 'agent'
    config.headers.token = localStorage.getItem('userToken') ? `JBY ${localStorage.getItem('userToken')}` : ''
    config.headers.encrypt =
    localStorage.getItem('clientSessionId') && localStorage.getItem('safeCode')
      ? md5(localStorage.getItem('safeCode') + t + localStorage.getItem('clientSessionId') + 'agent')
      : ''
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(function(response) {
  // const currTime = moment().format('YYYY-MM-DD')
  // // 不在登录页面 并且时间不对
  // if (currTime !== store.getters.validateTime && location.hash !== '#/login') {
  //   store.dispatch('logout')
  // }
  // if (
  //   (response.data.code === 410 ||
  //     response.data.code === '410' ||
  //     response.data.code === 416) &&
  //   location.hash !== '#/login'
  // ) {
  //   store.dispatch('logout')
  // }
  if (
    response.data.code &&
    response.data.code !== '200' &&
    response.data.code !== 200 &&
    Object.prototype.toString.call(response.data) === '[object Object]'
  ) {
    // 考试发布成绩不需要msg
    if (response.data.code !== 510) {
      message.error(response.data.msg || '请稍候再试或联系管理员！')
    }
  }
  return response
})
// Send a GET request to URL and return the json from the server.
export async function get(url, data = {}, headers = {}, responseType, options = {}) {
  return axios({
    method: 'get',
    url,
    params: data,
    headers: {
      ...headers
    },
    responseType: responseType,
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}

// Send a POST request to URL with json DATA and return the json from the
// server.
export async function post(url, data = {}, headers = {}, responseType, options = {}) {
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      ...headers
    },
    responseType: responseType,
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}

// Send a PUT request to URL with json DATA and return the json from the
// server.
export async function put(url, data = {}, headers = {}, options = {}) {
  return axios({
    method: 'put',
    url,
    data,
    headers: {
      ...headers
    },
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}

export async function patch(url, data = {}, headers = {}, options = {}) {
  return axios({
    method: 'patch',
    url,
    data,
    headers: {
      ...headers
    },
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}

// Send a DELETE request to URL and return the json from the server.
export async function del(url, data = {}, headers = {}, options = {}) {
  return axios({
    method: 'delete',
    url,
    data,
    headers: {
      ...headers
    },
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}

export async function download(url, data = {}, headers = {}, options = {}) {
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      ...headers
    },
    responseType: 'blob',
    ...options
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      message.error('请稍候再试或联系管理员！')
      return error
    })
}
